body {
    margin: 0;
    padding: 0;
    font-family: Arial, Roboto, Helvetica, sans-serif;
    min-height: 100vh;
    min-width: 1440px;
    -webkit-print-color-adjust: exact !important;
}

#root {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

/* HOT FIXES of KendoUI */
.k-animation-container {
    z-index: 10003
}
.k-dialog-wrapper {
    z-index: 10003
}

/* Customize React Toastify */
.Toastify__toast-body {
    font-family: Roboto,Arial,Helvetica,sans-serif;
    font-size: 12px;
}

.Toastify__toast--error {
    background-color: #984A97;
}

.Toastify__toast--info {
    background-color: #8B8B8B;
}

.Toastify__toast--warning {
    background-color: #FED633;
    color: black;
}
